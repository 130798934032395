<template>
  <div class="page">
    <router-view class="content"/>
    <mt-tabbar :value="selected" @input="onSelected">
      <mt-tab-item id="home">
        <img v-if="selected == 'home'" slot="icon" src="../assets/loan_1.png">
        <img v-else slot="icon" src="../assets/loan_2.png">
        {{ $t('home') }}
      </mt-tab-item>
      <mt-tab-item id="account">
        <img v-if="selected == 'account'" slot="icon" src="../assets/account_1.png">
        <img v-else slot="icon" src="../assets/account_2.png">
        {{ $t('account') }}
      </mt-tab-item>
    </mt-tabbar>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      selected: 'home',
    }
  },
  watch: {
    '$route.path': {
      handler(val) {
        if (val == '/home') {
          this.selected = 'home'
        } else {
          this.selected = 'account'
        }
      },
      immediate: true
    }
  },
  methods: {
    onSelected(val) {
      if (val == 'home') {
        this.$router.push('/home')
      } else {
        this.$router.push('/mine')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  .content {
    margin-bottom: -55px;
    box-sizing: border-box;
    padding-bottom: 55px;
    min-height: 100vh;
  }
  .mint-tabbar {
    position: relative;
  }
}
</style>
